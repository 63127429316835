.profile-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 92vh;
    width: 100%;

    .info-box{
        height: 80%;
        width: 60%;
        box-shadow: -2px 5px 20px rgb(60, 60, 60);
        border-radius: 10px;
        overflow: hidden;
        display: flex;

        @media (max-width: 600px){
            flex-direction: column;
        }

        .left-section{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 50%;
            background-color: black;
            color: white;
            justify-content: center;
            align-items: center;
            gap: 5%;
            @media (max-width: 600px){
                width: 100%;
                height: 50%;
            }

            div{
                margin-left: 5%;
                cursor: pointer;
                height: 15%;
                width: 80%;
                border-radius: 20px;
                display: flex;
                align-items: center;
                box-shadow: -2px 5px 10px rgb(160, 160, 160);
                padding-left: 5%;
            }
        }

        .right-section{
            background-color: white;
            height: 100%;
            width: 50%;
            scrollbar-color: black;
            @media (max-width: 600px){
                width: 100%;
                height: 50%;
            }

            .general-info-details{
                height: 100%;
                overflow: scroll;
            }
        }
    }
}