.security-details{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    @media (max-width: 600px){
        margin-bottom: 2em;
    }

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 80%;
        height: 80%;

        @media (max-width: 600px){
            gap: 10px;
            padding: 20px 0;
        }

        .message{
            text-align: center;
            @media (max-width: 600px){
                margin-top: 2em;
                font-size: 12px;
            }
        }
        
        input{
            box-shadow: -2px 5px 10px rgb(148, 148, 148);
            border-radius: 5px;
            border: none;
            outline: none;
            height: 2em;
            background-color: rgb(255, 255, 255);
            padding: 0 5px;
            text-align: center;
            color: rgb(0, 0, 0);
            font-size: 16px;
        }

        .button{
            height: 3em;
            width: 30%;
            background-color: rgb(255, 255, 255);
            border-radius: 5px;
            box-shadow: -2px 5px 10px rgb(148, 148, 148);
            border: none;
            cursor: pointer;
            transition: .3s;

            &:hover{
                background-color: rgb(0, 0, 0);
                color: white;
            }
        }
    }
}