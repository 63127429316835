.header-container{
    height: 8vh;
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .nav-links{
        color: white;
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 50px;
        margin-left: 10px;
        font-family: "Light";

        @media (max-width: 600px){
            gap: 10px;
            font-size: 4vw;
        }

        .logged-links{
            display: flex;
            gap: 50px;

            @media (max-width: 600px){
                gap: 10px;
            }

            .disabled-link{
                display: none;
            }

            .logout{
                cursor: pointer;
            }
        }
        
        span{
            color: white;
            text-decoration: none;
            transition: .5s;
            display: inline-flex;
            align-items: center;
            height: 5vh;
            border-radius: 10px;
            padding: 0 10px;
            
        }
        span:hover{
            background-color: rgba(154, 154, 154, 0.518);
        }
    }
}