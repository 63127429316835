.news-background{
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .news-modal{
        height: 60%;
        width: 40%;
        border-radius: 20px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .close-button{
			margin: 2% 2% 0 auto; 
			height: 20px;
			width: 20px;
			cursor: pointer;
		}

        .news-text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 2%;
            overflow-y: scroll;
        }
    }
}