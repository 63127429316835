.open-space-container{
    width: 100vw;
    display: flex;
    height: 92vh;

    @media (max-width: 500px){
        flex-direction: column;
        align-items: center;
    }

    .left-panel{
        width: 20vw;
        height: 100%;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @media (max-width: 500px){
            width: 100vw;
            height: 15%;
            margin-bottom: 2%;
            overflow: scroll;
        }

        .spaces-summary{
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 20px;

            .space-card{
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                width: 80%;
                min-height: 50px;
                border-radius: 5px;
                transition: .3s;
                box-shadow: -2px 5px 10px rgb(101, 101, 101);

                 span{
                    cursor: pointer;
                 }

                .admin-buttons{
                    display: flex;
                    gap: 10px;
                }
            }
            .active-space{
                background-color: rgba(79, 79, 79, 0.5);
                transform: scale(1.1);
            }
        }

        .add-task{
            cursor: pointer;
            margin: 0 10px 10px 0;
            color : rgb(50, 50, 50);
            background-color: white;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            transition: .2s;
            box-shadow: 0 2px 3px black;
            flex-shrink: 0;

            @media (max-width: 500px){
                height: 25px;
                width: 25px;
            }

            &:hover{
                transform: scale(1.2);
            }
        }
    }

    .work-section{
        width: 80vw;
        height: 100%;
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 2%;
        position: relative;

        @media (max-width: 500px){
            padding: 0;
        }

        .content{
            display: flex;
            align-items: center;
            gap: 20px;
            height: 15%;
            width: 100%;
            overflow-x: scroll;

            .tasks{
                display: flex;
                align-items: center;
                gap: 20px;
                padding: 2%;

                .task-card{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    cursor: pointer;
                    box-shadow: 0 2px 3px black;
                    padding: 5px;
                    border-radius: 5px;
                    transition: .3s;
                    width: 150px;
                    height: 25px;
                    overflow: hidden;

                    span{
                        width: 80%;
                        height: 100%;
                        overflow-x: hidden;
                    }

                    .admin-buttons{
                        display: flex;
                        gap: 10px;
                    }
                }

                .selected-task{
                    height: 80px;
                    width: 200px;
                    display: flex;
                    align-items: flex-start;

                    @media (max-width: 500px){
                        padding: 0;
                        height: 50px;
                        width: 250px;
                    }
                }
            }

            .add-task{
                cursor: pointer;
                color : rgb(50, 50, 50);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                transition: .2s;
                box-shadow: 0 2px 3px black;
                flex-shrink: 0;
    
    
                &:hover{
                    transform: scale(1.2);
                }
            }
        }

        .task-details{
            height: 80%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            @media(max-width: 600px){
                height: 100%;
                width: 100%;
                align-items: center;
            }
    
            .task-info{
                height: 80%;
                width: 80%;
                background-color: rgba(128, 128, 128, 0.1);
                box-shadow: -2px 5px 10px rgb(60, 60, 60);
                border-radius: 10px;
                padding: 20px;
                overflow-y: auto;
    
                @media(max-width: 600px){
                    margin: 0;
                    height: 70%;
                }
    
                .description-head{
                    display: flex;
                    justify-content: center;
    
                    .description-text{
                        width: 90%;
                        max-height: 25vh;
                        text-align: center;
                        box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
                        border-radius: 10px;
                        padding: 25px;
                        margin-bottom: 25px;
                        
                    }
                }
            }
        }

        .add-member{
            border-radius: 50%;
            height: 40px;
            width: 40px;
            box-shadow: 0 2px 3px black;
            position: absolute;
            bottom: 2%;
            left: 2%;
            cursor: pointer;
        }
    }
    .admin-icon{
        cursor: pointer;

        &:hover{
            transform: scale(1.2);
            transition: .3s;
        }
    }  
}