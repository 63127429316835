.bar-container{
    display: flex;
    justify-content: center;
    .progress-bar {
        width: 95%;
        background-color: rgba(255, 255, 255, 0);
        margin-bottom: 2em;
        border-radius: 15px;
        box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
    }
      
    .progress-bar-fill {
        height: 30px;
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
        border-radius: 15px;
        transition: .5s;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}


.add-step{
    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0 2px 3px black;
    transition: .2s;

    &:hover{
        transform: scale(1.2);
    }
}