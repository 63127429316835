.modal-wrapper{
    display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.6);

    .modal{
        background-color: rgba(255, 255, 255, 0.9);
		box-shadow: 0 5px 10px rgb(60, 60, 60);
        height: 60%;
        width: 60vh;
        border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
		
		.close-button{
			margin: 2% 2% 0 auto; 
			height: 20px;
			width: 20px;
			cursor: pointer;
		}

		.error-message{
			margin: 0;
			background-color: rgba(135, 135, 135, 0.84);
			padding: 10px;
			border-radius: 10px;
			
		}

		.members{
			height: 30%;
			width: 70%;
			display: flex;
			flex-direction: column;
			gap: 10px;
			overflow: scroll;
			flex-wrap: wrap;

			.member{
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: fit-content;
				gap: 10px;

				.not-admin{
					color: rgba(120, 120, 120, 0.5);
				}

				.pointer{
					cursor: pointer;
				}

				.admin{
					color: black;
				}
			}

			div{
				color: black;
			}
		}
		
		.admin-buttons{
			height: 30%;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;

			input, textarea, button{
				width: 80%;
				height: 50%;
				border: 2px solid black;
				outline: none;
				border-radius: 10px;
				background-color: black;
				color: white;
				text-align: center;
			}
		}

		h1{
			margin: 0;
		}

		input, textarea, button{
			width: 70%;
			height: 100%;
			border: 2px solid black;
			outline: none;
			border-radius: 10px;
			background-color: black;
			color: white;
			text-align: center;
		}
		input, button{
			height: 10%;
		}
		button{
			width: 30%;
			cursor: pointer;
			margin-bottom: 2%;
		}
    }
}