.details{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .details-head{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2em;
        gap: 20px;

        .image{
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: 1px solid black;
            cursor: pointer;
            transition: .2s;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 600px){
                width: 75px;
                height: 75px;
            }

            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
                border-radius: 50%;
            }

            .image-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                opacity: 0;
            }
        }
        .username{
            box-shadow: -2px 5px 10px rgb(148, 148, 148);
            border-radius: 5px;
            border: none;
            outline: none;
            height: 4em;
            background-color: rgb(0, 0, 0);
            padding: 0 5px;
            text-align: center;
            color: white;
            font-size: 1em;
        }

        .image::before{
            background-color: rgba(0, 0, 0, 0.3);
            height: 100%;
            width: 100%;
            content: "";
            position: absolute;
            border-radius: 50%;
            opacity: 0;
        }
        .image:hover .image-icon{
            opacity: 1;
        }
        .image:hover::before{
            opacity: 1;
        }
    }

    .details-body{
        height: 75%;
        display: flex;
        flex-direction: column  ;
        text-align: center;
        padding: 5%;
        align-items: space-between;
        justify-content: space-around;

        .stats{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .active-tasks-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .active-tasks{
                    @media (max-width: 600px){
                        font-size: 4vw;
                    }
                }
    
                .active-tasks-number{
                    font-size: 50px;
                }
            }
            .completed-tasks-container{
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .completed-tasks{
                    @media (max-width: 600px){
                        font-size: 4vw;
                    }
                }
    
                .completed-tasks-number{
                    font-size: 50px;
                }
            }
            span{
                width: 50%;
            }
        }
        .bar-container{
            display: flex;
            justify-content: center;
            .progress-bar {
                width: 95%;
                background-color: rgba(255, 255, 255, 0);
                margin-bottom: 2em;
                border-radius: 15px;
                box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
            }
              
            .total-progress {
                height: 30px;
                background-color: rgb(0, 0, 0);
                box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
                border-radius: 15px;
                transition: .5s;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        
    }
}