.sign-container{
    height: 92vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    form{
        height: 50vh;
        width: 30vw;
        background-color: rgba(128, 128, 128, 0.1);
        border-radius: 10px;
        box-shadow: -2px 5px 10px rgb(60, 60, 60);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media (max-width: 1000px){
            width: 60%;
            height: 60%;
        }

        @media (max-width: 600px){
            width: 80%;
        }

        .error-box{
            text-align: center;
            font-size: 12px;
        }

        *{
            width: 50%;
        }
        .buttons{
            height: 10%;
            display: flex;
            justify-content: space-between;

            button{
                width: 40%;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: -2px 5px 10px rgb(60, 60, 60);
                border-radius: 8px;
                border-style: none;
                color: black;
                cursor: pointer;
                padding: 0;
                text-align: center;
                transition: all .3s;
                transform: scale(1);

                @media (max-width: 600px){
                    font-size: 4vw;
                }
            }
            button:hover {
                transform: scale(1.1);
            }
        }
    }

    .loading-container{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .pizza-animation{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 8px solid #d1914b;
        box-sizing: border-box;
        --c:no-repeat radial-gradient(farthest-side, #d64123 94%,#0000);
        --b:no-repeat radial-gradient(farthest-side, #000 94%,#0000);
        background:
        var(--c) 11px 15px,
        var(--b) 6px 15px,    
        var(--c) 35px 23px,
        var(--b) 29px 15px,    
        var(--c) 11px 46px,
        var(--b) 11px 34px,    
        var(--c) 36px 0px,
        var(--b) 50px 31px,
        var(--c) 47px 43px,
        var(--b) 31px 48px,    
        #f6d353; 
        background-size: 15px 15px,6px 6px;
        animation: l4 3s infinite;
        }
        @keyframes l4 {
            0%     {-webkit-mask:conic-gradient(#0000 0     ,#000 0)}
            16.67% {-webkit-mask:conic-gradient(#0000 60deg ,#000 0)}
            33.33% {-webkit-mask:conic-gradient(#0000 120deg,#000 0)}
            50%    {-webkit-mask:conic-gradient(#0000 180deg,#000 0)}
            66.67% {-webkit-mask:conic-gradient(#0000 240deg,#000 0)}
            83.33% {-webkit-mask:conic-gradient(#0000 300deg,#000 0)}
            100%   {-webkit-mask:conic-gradient(#0000 360deg,#000 0)}
        }
    }

        input{
            height: 10%;
            text-align: center;
            border-radius: 10px;
            background-color: rgba(128, 128, 128, 0.1);
            box-shadow: -2px 5px 10px rgb(60, 60, 60);
            border: none;
            outline: none;
            transition: .5s;

            &:focus{
                box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
            }
        }
    }
