.step{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    height: 5vh;
    color: black;

    .step-icon{
        cursor: pointer;
        transition: .2s;
        padding: 0.5em;

        &:hover{
            transform: scale(1.2);
        }
    }

    .step-name{
        background-color: transparent;
        border: none;
        outline: none;
        width: fit-content;
    }

    .checkbox{
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        min-height: 1.5em;
        min-width: 1.5em;
        border-radius: 50%;
        transition: .5s;
        margin-left: 0;
        box-shadow: inset 0 2px 6px black;
        cursor: pointer;

    }
    .checked {
        position: relative;
        background-color: rgb(232, 232, 232);
    }
}


