.home-container{
    width: 100%;
    display: flex;
    height: 92vh;

    @media(max-width: 600px){
        flex-direction: column;
        align-items: center;
    }
    

    .task-panel{
        margin-top: 2em;
        width: 33vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @media(max-width: 600px){
            width: 100%;
            height: 20vh;
            max-height: 46vh;
            justify-content: center;
        }

        .tasks-container{
            padding-top: 2em;
            color: black;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px; 
            width: 100%;
            overflow-y: auto;

            @media(max-width: 600px){
                width: 80%;
                height: 90%;
                margin: 0;
                flex-shrink: 0;
                box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
                border-radius: 10px;
                padding-top: 20px;
            }

            .add-task{
                cursor: pointer;
                margin: 0 10px 10px 0;
                color : rgb(50, 50, 50);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                transition: .2s;
                box-shadow: 0 2px 3px black;
                flex-shrink: 0;


                &:hover{
                    transform: scale(1.2);
                }
            }

            .task{
                height: 3vw;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                background-color: rgba(128, 128, 128, 0.1);
                border-radius: 5px;
                box-shadow: -2px 5px 10px rgb(60, 60, 60);
                transition: .5s;
                font-size: 1.3vw;
                padding: 10px 0;

                @media (max-width: 1000px){
                    height: 5vw;
                    font-size: 1.5vw;
                    width: 80%;
                }
                @media (max-width: 600px){
                    font-size: 4vw;
                    width: 60%;
                    min-height: 10vw;
                }



                .task-icon{
                    cursor: pointer;
                    transition: .2s;

                    @media(max-width: 600px){
                        width: 3vw;
                        height: 3vw;
                    }

                &:hover{
                    transform: scale(1.2);
                }
                }

                .task-name{
                    background-color: transparent;
                    border: none;
                    outline: none;
                    width: 50%;
                    cursor: pointer;
                }
            }
            .selected-task{
                transform: scale(1.2);
                transition: .5s;
            }
        }
    }
    
    .task-details{
        height: 100%;
        width: 67vw;
        display: flex;
        justify-content: center;

        @media(max-width: 600px){
            height: 100%;
            width: 100%;
            align-items: center;
        }

        .task-info{
            height: 80%;
            width: 80%;
            background-color: rgba(128, 128, 128, 0.1);
            box-shadow: -2px 5px 10px rgb(60, 60, 60);
            border-radius: 10px;
            margin-top: 2em;
            padding: 20px;
            overflow-y: auto;

            @media(max-width: 600px){
                margin: 0;
                height: 70%;
            }

            .description-head{
                display: flex;
                justify-content: center;

                .description-text{
                    width: 90%;
                    max-height: 25vh;
                    text-align: center;
                    box-shadow: inset -2px 5px 10px rgb(60, 60, 60);
                    border-radius: 10px;
                    padding: 25px;
                    margin-bottom: 25px;
                    
                }
            }
        }
    }
}