.welcome-container{
    width: 100%;
    height: 92vh;
    align-items: center;
    justify-content: center;

    .intro-container{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .intro-box{
            display: grid;
            grid-template-rows: repeat(3, 1fr);
            flex-direction: column;
            align-items: center;
            box-shadow: -2px 5px 10px rgb(60, 60, 60);
            border-radius: 10px;
            width: 33%;
            height: 50%;
            text-align: center;
            aspect-ratio: 1/1;

            @media (max-width: 1000px){
                font-size: 3vw;
                width: 60%;
                height: 60%;
            }
            @media (max-width: 600px){
                height: 50%;
                width: 90%;
                font-size: 4vw;
            }
            .title{
                width: 100%;
                background-color: black;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                h1{
                    font-size: 3em;
                    color: white;   
                    margin: 0;
                }
            }
            
            .intro-text{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                h3{
                    margin: 0;
                }
            }
            .button{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .start-button{
                    width: 30%;
                    height: 30%;
                    background-color: black;
                    color: white;
                    border-radius: 10px;
                    border: none;
                    text-decoration: none;
                    text-align: center;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    transition: .2s;
                }
                .start-button:focus{
                    background-color: rgb(130, 130, 130);
                    color: black;
                }
                .start-button:hover{
                    background-color: rgb(45, 45, 45);
                }
            }
    
            
        }
    }
    
}